import React from 'react'

import Trademark from './trademark-disputes'

const TrademarkDispute = () => (
  <Trademark py={5}>
    <Trademark.Header>Sitebuilder Trademark Disputes for Domain Names</Trademark.Header>
    <Trademark.subSection pt={3}>
      <Trademark.Text>
        If you have a dispute regarding your trademark being used within a domain name registered
        through us, please refer to ICANN’s Uniform Domain-Name Dispute-Resolution Policy ("UDRP") (
        <a href="https://www.icann.org/resources/pages/help/dndr/udrp-en">available here</a>) for
        information about your options.
        <br />
        <br />
        If you have a complaint about the use of your trademark on a website hosted by us, please
        use the Report Member Violations form available <a href="/abuse">here</a>.
      </Trademark.Text>
    </Trademark.subSection>
  </Trademark>
)

export default TrademarkDispute
