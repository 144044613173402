import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { space } from 'styled-system'

const Trademark = styled.section({}, space)

Trademark.subSection = styled.div({}, space)

Trademark.Header = styled(Typography).attrs(() => ({ variant: 'h1' }))({}, space)

Trademark.Text = styled(Typography).attrs(() => ({ variant: 'body1', component: 'span' }))``

export default Trademark
